<template>
    <div class="layout-header-section d-lg-flex d-block justify-content-between">
        <div class="header-label col-lg-6 col-12">Bureau Records</div>
    </div>
    <div class="search-voter-section-outer" style="height: calc(100vh - 141px);">
        <div class="layout-content-section">
            <div class="form-box-outer">
                <div class="row">
                    <div class="col-lg-8 col-md-8 col-12">
                        <div class="row">
                            <div class="col-lg-4 col-md-4 col-12">
                                <div class="custom-form-group">
                                    <label class="form-label">Mobile Number<span class="text-danger">*</span></label>
                                    <div class="input-group custom-input-group">
                                        <div class="col-lg-4 col-md-4 col-12">
                                            <Multiselect v-model="countrycode" :options="countryCodeList"
                                                :searchable="true" label="label" placeholder="Select" disabled
                                                class="multiselect-custom custom-title-multiselect" :canClear="false"
                                                :closeOnSelect="true" :object="true" noOptionsText="No Result"
                                                :canDeselect="false" />
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-12">
                                            <input type="text" :disabled="disabledinput" v-model="searchmobile"
                                                class="form-control" id="formSearchMobileNoInput"
                                                placeholder="Enter mobile number" maxlength="10"
                                                @keypress="onlyNumberMobile" autocomplete="off" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-3 col-12">
                                <div class="custom-form-group">
                                    <label class="form-label">First Name</label>
                                    <div class="custom-form-group">
                                        <input type="text" :disabled="disabledinput" v-model="firstname"
                                            class="form-control text-capitalize" placeholder="Enter First Name"
                                            maxlength="20" @keypress="isLetterWithOutSpace($event)"
                                            autocomplete="off" />
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-2 col-md-2 col-12">
                                <div class="custom-form-group" style="margin-top: 26px;">
                                    <label class="form-label"></label>
                                    <button type="button" class="header-btn-outer btn btn-primary"
                                        :style="{ width: '100px' }" @click="searchmobilehistory()"
                                        :disabled="searchmobile.length != 10 || disabledinput">
                                        <span v-if="!btnloader">Search</span>
                                        <div class="
                                        spinner-border
                                        text-light
                                        custom-spinner-loader-btn
                                    " role="status" v-if="btnloader">
                                            <span class="visually-hidden">Loading...</span>
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                        <div class="row" >
                            <div class="col-lg-6 col-md-6 col-12" v-if="localdatafound">
                                <div class="custom-form-group" style="margin-top: 26px;">
                                    <label class="form-label"></label>
                                    <button type="button" class="btn form-submit-btn" :style="{ width: '130px' }"
                                        @click="deletelocaldata()" :disabled="localbtnloader">
                                        <span v-if="!localbtnloader"><i class="pi pi-trash me-1"
                                                style="font-size: 0.7rem"></i>Delete Data</span>
                                        <div class="
                                        spinner-border
                                        text-light
                                        custom-spinner-loader-btn
                                    " role="status" v-if="localbtnloader">
                                            <span class="visually-hidden">Loading...</span>
                                        </div>
                                    </button>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-12 text-end" v-if="clearbtnflag">
                                <div class="custom-form-group" style="margin-top: 26px;">
                                    <label class="form-label"></label>
                                    <button type="button" class="btn custom-view-detail-btn"
                @click="searchvoterclearbtn()">Clear All
            </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="search-tab-outer-scroll" v-if="mobiledetailshowflag">
                    <div v-if="this.masterdata != ''">
                        <div class="voter-exit-box-outer mb-3" v-if="votergovdata.data.personal_info.length != 0">
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-12">
                                    <div class="custom-form-group">
                                        <label class="individual-header-label">Personal Information</label>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12 col-12">
                                    <div class="row">
                                        <div class="col-lg-4 col-md-4 col-12">
                                            <div class="customer-label-group mb-2">
                                                <label class="form-label">Full Name</label>
                                                <div class="from-label-value text-capitalize">
                                                    {{
                                                votergovdata.data.personal_info.full_name
                                                    ?
                                                    votergovdata.data.personal_info.full_name
                                                    :
                                                    "N/A" }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-12">
                                            <div class="customer-label-group mb-2">
                                                <label class="form-label">Date of Birth</label>
                                                <div class="from-label-value text-capitalize">
                                                    {{
                                                format_date(votergovdata.data.personal_info.dob)
                                            }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-12">
                                            <div class="customer-label-group mb-2">
                                                <label class="form-label">Gender</label>
                                                <div class="from-label-value text-capitalize">
                                                    {{ votergovdata.data.personal_info.gender ?
                                                votergovdata.data.personal_info.gender : "N/A"
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                        <Divider type="dashed" class="mt-0 mb-2" />
                                        <div class="col-lg-4 col-md-4 col-12">
                                            <div class="customer-label-group mb-2">
                                                <label class="form-label">Age</label>
                                                <div class="from-label-value text-capitalize">
                                                    {{ votergovdata.data.personal_info.age ?
                                                votergovdata.data.personal_info.age :
                                                "N/A" }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-12"
                                            v-if="votergovdata.data.personal_info.total_income != ''">
                                            <div class="customer-label-group mb-2">
                                                <label class="form-label">Total Income</label>
                                                <div class="from-label-value text-capitalize">
                                                    {{
                                                vueNumberFormat(votergovdata.data.personal_info.total_income,
                                                    {
                                                        prefix: "₹ ",
                                                        decimal: ".",
                                                        thousand: ",",
                                                        precision: 0,
                                                        acceptNegative: true,
                                                    })
                                            }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-12">
                                            <div class="customer-label-group mb-2">
                                                <label class="form-label">Occupation</label>
                                                <div class="from-label-value text-capitalize">
                                                    {{ votergovdata.data.personal_info.occupation ?
                                                votergovdata.data.personal_info.occupation
                                                : "N/A" }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="voter-exit-box-outer mb-3" v-if="votergovdata.data.phone_info != null">
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-12">
                                    <div class="custom-form-group">
                                        <label class="individual-header-label">Contact Details</label>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12 col-12">
                                    <!-- <div class="row" v-for="msgperrgh in votergovdata.data.phone_info" :key="msgperrgh">
                                        <div class="col"> -->
                                    <div class="row mb-2" v-for="(msgphone, index) in votergovdata.data.phone_info"
                                        :key="msgphone"
                                        v-bind:class="{ dividerrowhide: index == votergovdata.data.phone_info.length - 1 }">
                                        <div class="col-lg-4 col-md-4 col-12">
                                            <div class="customer-label-group mb-2">
                                                <label class="form-label">Number</label>
                                                <div class="from-label-value text-capitalize">
                                                    {{ msgphone.number ? msgphone.number : "N/A" }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-12" v-if="msgphone.reported_date">
                                            <div class="customer-label-group mb-2">
                                                <label class="form-label">Date</label>
                                                <div class="from-label-value text-capitalize">
                                                    {{ format_date(msgphone.reported_date) }}
                                                </div>
                                            </div>
                                        </div>
                                        <Divider class="mt-0 mb-2" />
                                        <!-- </div>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="voter-exit-box-outer mb-3" v-if="votergovdata.data.email_info != null">
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-12">
                                    <div class="custom-form-group">
                                        <label class="individual-header-label">Email Address</label>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12 col-12">
                                    <!-- <div class="row" v-for="msgperrgh in ccr_response.cir_report_data_lst" :key="msgperrgh">
                                        <div class="col"> -->
                                    <div class="row mb-2" v-for="(msgemail, index) in votergovdata.data.email_info"
                                        :key="msgemail"
                                        v-bind:class="{ dividerrowhide: index == votergovdata.data.email_info.length - 1 }">
                                        <div class="col-lg-4 col-md-4 col-12" v-if="msgemail.email_address">
                                            <div class="customer-label-group mb-2">
                                                <label class="form-label">Email Address</label>
                                                <div class="from-label-value text-capitalize">
                                                    {{ msgemail.email_address ? msgemail.email_address : "N/A" }}
                                                </div>
                                            </div>
                                        </div>
                                        <Divider class="mt-0 mb-2" />
                                    </div>
                                    <!-- </div>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                        <div class="voter-exit-box-outer mb-3" v-if="votergovdata.data.address_info.length != 0">
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-12">
                                    <div class="custom-form-group">
                                        <label class="individual-header-label">Address</label>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12 col-12">
                                    <!-- <div class="row" v-for="msgperrgh in ccr_response.cir_report_data_lst" :key="msgperrgh">
                                        <div class="col"> -->
                                    <div class="row mb-2"
                                        v-for="(msgaddresszz, index) in votergovdata.data.address_info"
                                        :key="msgaddresszz"
                                        v-bind:class="{ dividerrowhide: index == votergovdata.data.address_info.length - 1 }">
                                        <div class="col-lg-8 col-md-8 col-12">
                                            <div class="customer-label-group mb-2">
                                                <label class="form-label">Address</label>
                                                <div class="from-label-value text-capitalize">
                                                    {{ msgaddresszz.address ? msgaddresszz.address : "N/A" }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-12">
                                            <div class="customer-label-group mb-2">
                                                <label class="form-label">State</label>
                                                <div class="from-label-value text-capitalize">
                                                    {{ msgaddresszz.state ? msgaddresszz.state : "N/A" }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-12">
                                            <div class="customer-label-group mb-2">
                                                <label class="form-label">Postal</label>
                                                <div class="from-label-value text-capitalize">
                                                    {{ msgaddresszz.postal ? msgaddresszz.postal : "N/A" }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-12" v-if="msgaddresszz.reported_date">
                                            <div class="customer-label-group mb-2">
                                                <label class="form-label">Date</label>
                                                <div class="from-label-value text-capitalize">
                                                    {{ format_date(msgaddresszz.reported_date) }}
                                                </div>
                                            </div>
                                        </div>
                                        <Divider class="mt-0 mb-2" />
                                    </div>
                                    <!-- </div>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                        <div class="voter-exit-box-outer mb-3"
                            v-if="votergovdata.data.identity_info.pan_number.length != 0">
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-12">
                                    <div class="custom-form-group">
                                        <label class="individual-header-label">PAN Card Details</label>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12 col-12">
                                    <div class="row"
                                        v-for="(msjee, index) in votergovdata.data.identity_info.pan_number"
                                        :key="msjee"
                                        v-bind:class="{ dividerrowhide: index == votergovdata.data.identity_info.pan_number.length - 1 }">
                                        <div class="col-lg-4 col-md-4 col-12">
                                            <div class="customer-label-group mb-2">
                                                <div class="from-label-value text-capitalize">
                                                    {{ msjee.id_number ? msjee.id_number : "N/A"
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="voter-exit-box-outer mb-3"
                            v-if="votergovdata.data.identity_info.voter_id.length != 0">
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-12">
                                    <div class="custom-form-group">
                                        <label class="individual-header-label">Voter Id Number</label>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12 col-12">
                                    <div class="row"
                                        v-for="(msjeell, index) in votergovdata.data.identity_info.voter_id"
                                        :key="msjeell"
                                        v-bind:class="{ dividerrowhide: index == votergovdata.data.identity_info.voter_id.length - 1 }">
                                        <div class="col-lg-4 col-md-4 col-12">
                                            <div class="customer-label-group mb-2">
                                                <label class="form-label">Id Number</label>
                                                <div class="from-label-value text-capitalize">
                                                    {{ msjeell.id_number ?
                                                msjeell.id_number :
                                                "N/A" }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="voter-exit-box-outer mb-3"
                            v-if="votergovdata.data.identity_info.aadhaar_number.length != 0">
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-12">
                                    <div class="custom-form-group">
                                        <label class="individual-header-label">Aadhaar Number</label>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12 col-12">
                                    <div class="row"
                                        v-for="(aadharmsjeell, index) in votergovdata.data.identity_info.aadhaar_number"
                                        :key="aadharmsjeell"
                                        v-bind:class="{ dividerrowhide: index == votergovdata.data.identity_info.aadhaar_number.length - 1 }">
                                        <div class="col-lg-4 col-md-4 col-12">
                                            <div class="customer-label-group mb-2">
                                                <div class="from-label-value text-capitalize">
                                                    {{ aadharmsjeell.id_number ?
                                                aadharmsjeell.id_number :
                                                "N/A" }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="voter-exit-box-outer mb-3"
                            v-if="votergovdata.data.identity_info.driving_license.length != 0">
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-12">
                                    <div class="custom-form-group">
                                        <label class="individual-header-label">Driving License Number</label>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12 col-12">
                                    <div class="row"
                                        v-for="(drivingrmsjeell, index) in votergovdata.data.identity_info.driving_license"
                                        :key="drivingrmsjeell"
                                        v-bind:class="{ dividerrowhide: index == votergovdata.data.identity_info.driving_license.length - 1 }">
                                        <div class="col-lg-4 col-md-4 col-12">
                                            <div class="customer-label-group mb-2">
                                                <label class="form-label">Id Number</label>
                                                <div class="from-label-value text-capitalize">
                                                    {{ drivingrmsjeell.id_number ?
                                                drivingrmsjeell.id_number :
                                                "N/A" }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="voter-exit-box-outer mb-3"
                            v-if="votergovdata.data.identity_info.passport_number.length != 0">
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-12">
                                    <div class="custom-form-group">
                                        <label class="individual-header-label">Passport Number</label>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12 col-12">
                                    <div class="row"
                                        v-for="(passportrmsjeell, index) in votergovdata.data.identity_info.passport_number"
                                        :key="passportrmsjeell"
                                        v-bind:class="{ dividerrowhide: index == votergovdata.data.identity_info.passport_number.length - 1 }">
                                        <div class="col-lg-4 col-md-4 col-12">
                                            <div class="customer-label-group mb-2">
                                                <label class="form-label">Id Number</label>
                                                <div class="from-label-value text-capitalize">
                                                    {{ passportrmsjeell.id_number ?
                                                passportrmsjeell.id_number :
                                                "N/A" }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="voter-exit-box-outer mb-3"
                            v-if="votergovdata.data.identity_info.ration_card.length != 0">
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-12">
                                    <div class="custom-form-group">
                                        <label class="individual-header-label">Ration Card Number</label>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12 col-12">
                                    <div class="row"
                                        v-for="(rationrmsjeell, index) in votergovdata.data.identity_info.ration_card"
                                        :key="rationrmsjeell"
                                        v-bind:class="{ dividerrowhide: index == votergovdata.data.identity_info.ration_card.length - 1 }">
                                        <div class="col-lg-4 col-md-4 col-12">
                                            <div class="customer-label-group mb-2">
                                                <label class="form-label">Id Number</label>
                                                <div class="from-label-value text-capitalize">
                                                    {{ rationrmsjeell.id_number ?
                                                rationrmsjeell.id_number :
                                                "N/A" }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="voter-exit-box-outer mb-3"
                            v-if="votergovdata.data.identity_info.other_id.length != 0">
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-12">
                                    <div class="custom-form-group">
                                        <label class="individual-header-label">Other Id's</label>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12 col-12">
                                    <div class="row"
                                        v-for="(otherrmsjeell, index) in votergovdata.data.identity_info.other_id"
                                        :key="otherrmsjeell"
                                        v-bind:class="{ dividerrowhide: index == votergovdata.data.identity_info.other_id.length - 1 }">
                                        <div class="col-lg-4 col-md-4 col-12">
                                            <div class="customer-label-group mb-2">
                                                <label class="form-label">Id Number</label>
                                                <div class="from-label-value text-capitalize">
                                                    {{ otherrmsjeell.id_number ?
                                                otherrmsjeell.id_number :
                                                "N/A" }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>



                    </div>
                    <div class="voter-exit-box-outer pb-3" v-if="this.masterdata == ''">
                        <div>No records found.</div>
                    </div>
                </div>
                <div class="voter-exit-box-outer pb-3" v-if="nodatafoundflag">
                    <div>No records found.</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import ApiService from "../../service/ApiService";
import useValidate from "@vuelidate/core";
import moment from 'moment';
export default {
    data() {
        return {
            v$: useValidate(),
            countrycode: { value: 101, label: "+91" },
            countryCodeList: [],
            searchmobile: "",
            firstname: '',
            btnloader: false,
            disabledinput: false,
            mobiledetailshowflag: false,
            votergovdata: '',
            ccr_response: '',
            nodatafoundflag: false,
            localdatafound: false,
            localbtnloader: false,
            masterdata: '',
            clearbtnflag: '',
        };
    },
    validations() {
        return {
        };
    },
    ApiService: null,
    created() {
        this.ApiService = new ApiService();
    },
    mounted() {
    },
    methods: {
        searchmobilehistory() {
            this.btnloader = true;
            let fields = {};
            fields["mobile_number"] = this.searchmobile;
            fields["name"] = this.firstname;
            this.ApiService.getPrefillDetails(fields).then((data) => {
                if (data.status === true) {
                    if (data.datastatus == 1) {
                        this.localdatafound = true;
                    } else {
                        this.localdatafound = false;
                    }
                    this.btnloader = false;
                    this.masterdata = data.data;

                    if (this.masterdata != '') {
                        this.votergovdata = JSON.parse(data.data.response);
                    }
                    this.mobiledetailshowflag = true;
                    this.disabledinput = true;
                    this.clearbtnflag = true;
                } else {
                    this.btnloader = false;
                    this.votergovdata = '';
                    this.mobiledetailshowflag = false;
                    this.nodatafoundflag = true;
                    this.clearbtnflag = false;
                }
            });
        },
        deletelocaldata() {
            let fields = {};
            fields["mobile_number"] = this.searchmobile;
            this.ApiService.deleteprefilldata(fields).then((data) => {
                this.localbtnloader = true;
                if (data.status === true) {
                    this.localbtnloader = false;
                    this.mobiledetailshowflag = false;
                    this.searchmobile = '';
                    this.firstname = '';
                    var successMsg = "Deleted successfully";
                    this.$toast.open({
                        message: successMsg,
                        type: "success",
                        duration: 3000,
                        position: "top-right",
                    });
                    this.localdatafound = false;
                    this.disabledinput = false;
                } else {
                    this.localbtnloader = false;
                }
            });
        },
        isLetterWithOutSpace(e) {
            let char = String.fromCharCode(e.keyCode);
            if (/^[A-Za-z]+$/.test(char)) return true;
            else e.preventDefault();
        },
        onlyNumberMobile($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
                $event.preventDefault();
            }

            var mobileInput = document.getElementById("formSearchMobileNoInput");
            if ((mobileInput.value == '' || mobileInput.length > 1) && (keyCode == 48 || keyCode == 49 || keyCode == 50 || keyCode == 51 || keyCode == 52)) {
                $event.preventDefault();
            }
        },
        format_date(value) {
            if (value) {
                return moment(String(value)).format("DD/MM/YYYY");
            } else {
                return 'N/A';
            }
        },
        searchvoterclearbtn() {
            this.searchmobile = '';
            this.btnloader = false;
            this.disabledinput = false;
            this.mobiledetailshowflag = false;
            this.ccr_response = '';
            this.nodatafoundflag = false;
            this.localdatafound = false;
            this.localbtnloader = false;
            this.firstname = '';
            this.masterdata = '';
            this.clearbtnflag = false;
            this.votergovdata = '';
        },
    },
};
</script>
<style scoped>
.form-box-outer {
    padding: 16px 16px;
    margin-bottom: 15px;
    border: 1px solid #e0e5ed;
    border-radius: 0px 0px 4px 4px;
}

.search-voter-section-outer {
    display: block;
    overflow-y: auto;
    overflow-x: hidden;
}

.search-voter-section-outer::-webkit-scrollbar {
    display: block;
}

.search-voter-section-outer::-webkit-scrollbar {
    width: 0.3em;
    height: 0.3em;
}

.search-voter-section-outer::-webkit-scrollbar-thumb {
    background-color: rgb(113 115 119 / 49%);
    border-radius: 3px;
}

.search-tab-outer-scroll {
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
}

.search-tab-outer-scroll::-webkit-scrollbar {
    display: block;
}

.search-tab-outer-scroll::-webkit-scrollbar {
    width: 0.3em;
    height: 0.3em;
}

.search-tab-outer-scroll::-webkit-scrollbar-thumb {
    background-color: rgb(113 115 119 / 49%);
    border-radius: 3px;
}

.voter-exit-box-outer {
    padding: 16px 16px 0;
    background: #e4f8f0 0% 0% no-repeat padding-box;
    border: 1px solid #bbd9cc;
}

.customer-label-group {
    margin-bottom: 20px;
}

.customer-label-group .form-label {
    font-family: "AcuminPro-Regular";
    font-size: 13px;
    letter-spacing: 0px;
    color: #4a5463;
    line-height: 13px;
    margin-bottom: 6px;
}

.customer-label-group .from-label-value {
    font-family: "AcuminPro-SemiBold";
    font-size: 14px;
    letter-spacing: 0.28px;
    color: #2b2e38;
    line-height: 20px;
}

.individual-header-label {
    font-family: 'AcuminPro-SemiBold';
    font-size: 13px;
    text-align: left;
    letter-spacing: 0.52px;
    color: #0d488b;
    line-height: 15px;
}

.dividerrowhide .p-divider {
    display: none;
}

.form-submit-btn.btn {
    background: #ef8080 0% 0% no-repeat padding-box;
    border-color: #de2525;
    border-radius: 4px;
    letter-spacing: 0.26px;
    color: #ffffff;
    font-size: 13px;
    line-height: 20px;
    padding: 6px 15px;
    font-family: "AcuminPro-Regular";
    min-width: 110px;
}

.form-submit-btn:focus {
    box-shadow: none;
}
</style>